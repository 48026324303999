window.tinymce = window.tinymce || false
document.addEventListener("turbo:load", function () {
  if(!window.tinymce){ console.warn("tinyMCE not loaded"); return;}
  tinymce.init({
    language: "fr_FR",
    selector: "textarea.tinymce",
    toolbar: ["undo redo | bold italic underline"],
    default_font_stack: ["-apple-system", "Roboto", "sans-serif"],
    content_style: "body { font-family:Roboto,sans-serif; font-size:16px }",
    menubar: "file edit insert format",
    menu: {
      format: {
        title: "Format",
        items: "bold italic underline | blocks | removeformat"
      },
      insert: { title: "Insert", items: "image media | hr | anchor" }
    },
    block_formats: "Paragraph=p; Header 2=h2; Header 3=h3",
    style_formats: [
      { title: "Headings", items: [{ title: "Heading 2", format: "h2" }] },
      {
        title: "Inline",
        items: [
          { title: "Bold", format: "bold" },
          { title: "Italic", format: "italic" },
          { title: "Underline", format: "underline" }
        ]
      },
      { title: "Blocks", items: [{ title: "Paragraph", format: "p" }] },
      {
        title: "Align",
        items: [
          { title: "Center", format: "aligncenter" },
          { title: "Justify", format: "alignjustify" }
        ]
      }
    ],
    statusbar: true,
    license_key: 'gpl',
    setup: function (editor) {
      editor.on("init", function () {
        this.getDoc().body.style.fontSize = '16px';
        this.getDoc().body.style.fontFamily = 'Roboto';
      });
      editor.on("change", function () {
        var value = editor.save();
        document.getElementById(editor.id).innerHTML = value;
      });
    }
  });
});
