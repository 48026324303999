document.addEventListener("turbo:load", function () {
    const indeterminateCheckboxes = document.querySelectorAll(".checkbox-indeterminate");
    
    if (!indeterminateCheckboxes.length) {
      return;
    }
    indeterminateCheckboxes.forEach((checkbox) => {
        checkbox.indeterminate = true
    })
})  
