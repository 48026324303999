document.addEventListener("turbo:load", () => {
  const waitElements = document.querySelectorAll(".js-wait");

  if (!waitElements.length) {
    return;
  }
  waitElements.forEach((debounceElement) => {
    debounceElement.classList.add("link-disabled");
    setTimeout(() => {
      debounceElement.classList.remove("link-disabled");
    }, 10000);

    debounceElement.addEventListener("click", (event) => {
      if (debounceElement.classList.contains("link-disabled")) {
        event.preventDefault();
        return;
      }
    });
  });
});
