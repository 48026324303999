import IMask from "imask";
import "/node_modules/flag-icons/css/flag-icons.min.css";
const PHONE_MASKS = [
  { mask: "+33 0 00 00 00 00", startsWith: "+33", flag: "fr" }, // France
  { mask: "+49 000 0000000", startsWith: "+49", flag: "de" }, // Germany
  { mask: "+34 000 000 000", startsWith: "+34", flag: "es" }, // Spain
  { mask: "+39 00 0000 0000", startsWith: "+39", flag: "it" }, // Italy
  { mask: "+351 000 000 000", startsWith: "+351", flag: "pt" }, // Portugal
  { mask: "+31 0 0000 0000", startsWith: "+31", flag: "nl" }, // Netherlands
  { mask: "+32 0 000 00 00", startsWith: "+32", flag: "be" }, // Belgium
  { mask: "+46 000 000 0000", startsWith: "+46", flag: "se" }, // Sweden
  { mask: "+47 000 00 000", startsWith: "+47", flag: "no" }, // Norway
  { mask: "+41 00 000 00 00", startsWith: "+41", flag: "ch" }, // Switzerland
  { mask: "+43 000 00000", startsWith: "+43", flag: "at" }, // Austria
  { mask: "+45 00 00 00 00", startsWith: "+45", flag: "dk" }, // Denmark
  { mask: "+358 0 000 0000", startsWith: "+358", flag: "fi" }, // Finland
  { mask: "+354 000 0000", startsWith: "+354", flag: "is" }, // Iceland
  { mask: "+353 0 000 0000", startsWith: "+353", flag: "ie" }, // Ireland
  { mask: "+30 000 000 0000", startsWith: "+30", flag: "gr" }, // Greece
  { mask: "+36 00 000 000", startsWith: "+36", flag: "hu" }, // Hungary
  { mask: "+48 000 000 000", startsWith: "+48", flag: "pl" }, // Poland
  { mask: "+40 000 000 000", startsWith: "+40", flag: "ro" }, // Romania
  { mask: "+420 000 000 000", startsWith: "+420", flag: "cz" }, // Czech Republic
  { mask: "+421 000 000 000", startsWith: "+421", flag: "sk" }, // Slovakia
  { mask: "+385 00 000 0000", startsWith: "+385", flag: "hr" }, // Croatia
  { mask: "+359 00 000 000", startsWith: "+359", flag: "bg" }, // Bulgaria
  { mask: "+372 0000 0000", startsWith: "+372", flag: "ee" }, // Estonia
  { mask: "+371 00 000 000", startsWith: "+371", flag: "lv" }, // Latvia
  { mask: "+370 000 00000", startsWith: "+370", flag: "lt" }, // Lithuania
  { mask: "+352 000 000", startsWith: "+352", flag: "lu" }, // Luxembourg
  { mask: "+356 0000 0000", startsWith: "+356", flag: "mt" }, // Malta
  { mask: "+386 0 0000 0000", startsWith: "+386", flag: "si" }, // Slovenia
  { mask: "+{0[00]} 000 000 0000", startsWith: "+" }, // Fallback for other international numbers
];

document.addEventListener("turbo:load", () => {
  const formElement = document.querySelector(".js-masked-form");

  if (!formElement) {
    console.warn("No form element found");
    return;
  }

  const inputElement = formElement.querySelector(".js-masked");

  if (!inputElement) {
    console.warn("No input elements found");
    return;
  }

  const maskOptions = getMaskOptions(inputElement);
  IMask(inputElement, maskOptions);
  formElement.addEventListener("turbo:submit-start", handleFormSubmit)
  const valueLength = inputElement.value.length;
  inputElement.setSelectionRange(valueLength, valueLength);
  inputElement.focus();
});

function getMaskOptions(inputElement) {
  if (inputElement.classList.contains('js-phone-mask') && inputElement.classList.contains('js-email-mask')) {
    throw new Error("should mask email OR phone, not both")
  } else if (inputElement.classList.contains('js-phone-mask')) {
    return {
      mask: PHONE_MASKS,
      prepareChar: (str) => str.toLowerCase(),
      dispatch: dispatchPhoneMaskForElement(inputElement),
    };
  } else if (inputElement.classList.contains('js-email-mask')) {
    return {
      mask: getEmailMask(),
      prepareChar: (str) => str.toLowerCase(),
    };
  } else {
    console.warn('No specific mask class found, applying default phone mask');
    return {
      mask: PHONE_MASKS,
      prepareChar: (str) => str.toLowerCase(),
      dispatch: dispatchPhoneMaskForElement(inputElement),
    };
  }
}




function getEmailMask() {
  return {
    mask: "v[x]y.z",
    blocks: {
      v: { mask: /[a-zA-Z0-9_+&*-]*/ },
      x: { mask: /(?:\.[a-zA-Z0-9_+&*-]*)*/ },
      y: { mask: /@(?:[a-zA-Z0-9-]*)*/ },
      z: { mask: /[a-zA-Z]*/ },
    },
    startsWith: "a",
    placeholderChar: "_",
    lazy: true,
  };
}

function currentCountry(classList) {
  const match = Array.from(classList).find((cls) => cls.startsWith(`fi-`))
  if(!match) return null;
  return match.replaceAll("fi-", "")
}
function changeCountry(input, domElement) {
  const phoneFlagElement = domElement.closest('label').querySelector('.js-phone-flag');
  if(!phoneFlagElement) return; 
  const currentFlag = currentCountry(phoneFlagElement.classList)

  const match = PHONE_MASKS.find(({startsWith: prefixNumber}) =>  input.startsWith(prefixNumber))
  if(!match || !match.flag) {
    // Set to international
    phoneFlagElement.classList.remove(`fi-${currentFlag}`)
    phoneFlagElement.classList.remove(`fi`)
    phoneFlagElement.classList.add(`fa`)
    phoneFlagElement.classList.add(`fa-globe`)

    return;
  }
  if(match.flag === currentFlag) return;
  phoneFlagElement.classList.remove(`fa`)
  phoneFlagElement.classList.remove(`fa-globe`)
  phoneFlagElement.classList.remove(`fi-${currentFlag}`)
  phoneFlagElement.classList.add(`fi`)
  phoneFlagElement.classList.add(`fi-${match.flag}`)
}
function dispatchPhoneMaskForElement(element) {
  return function dispatchPhoneMask(appended, dynamicMasked) {
    const input = dynamicMasked.value + appended;
  
    if (input.startsWith("+")) {
      const specificMask = dynamicMasked.compiledMasks.find((m) =>
        input.startsWith(m.startsWith)
      );
      if(typeof specificMask !== "undefined"){
        changeCountry(input, element)
      }
      return (
        specificMask ||
        dynamicMasked.compiledMasks.find((m) => m.startsWith === "+")
      );
    } else if (/[a-zA-Z]/.test(input[0])) {
      return dynamicMasked.compiledMasks[dynamicMasked.compiledMasks.length - 1];
    } else {
      return dynamicMasked.compiledMasks.find((m) => m.startsWith === "");
    }
  }
  
}

function handleFormSubmit(event) {
  const inputElement = document.querySelector(".js-masked")
  if(!inputElement) {
    throw new Error("unexpected error, .js-masked should be found")
  }
  const value = `${inputElement.value}`.replaceAll(" ", "")
  inputElement.classList.remove("input-error");

  const emailRegex =
    /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  const phoneRegex = /^(\+|0)\d{6,}$/;

  const isValidPhone = phoneRegex.test(value)
  const isValidEmail = emailRegex.test(value)
  const isValid = isValidPhone || isValidEmail
  if (!isValid) {
    const isPhoneLike = value.startsWith("0") || value.startsWith("+")
    event.detail.formSubmission.stop()
    if(isPhoneLike && inputElement.classList.contains('js-phone-mask'))
      inputElement.setCustomValidity(
        "Un numéro de téléphone valide est requis" 
      );
    else if(!isPhoneLike && inputElement.classList.contains('js-email-mask'))
      inputElement.setCustomValidity(
        "Un email valide est requis " 
      );
    else
      inputElement.setCustomValidity(
        "Vérifier ce champs " 
      );

    inputElement.reportValidity();
    inputElement.classList.add("input-error");
  } else {
    inputElement.setCustomValidity("");
  }
}
