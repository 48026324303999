import { Turbo } from "@hotwired/turbo-rails"
document.addEventListener("turbo:load", () => {

    const clickableRows = document.querySelectorAll("tr[data-href]");
    if(!clickableRows.length) return;
    clickableRows.forEach(row => row.addEventListener("click", (event) => {
        const href = row.dataset["href"];
        const target = event.target;
        if(target && target.dataset.turboDisabled)
            return;
        Turbo.visit(href)
    }))
});