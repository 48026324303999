document.addEventListener("turbo:load", function () {

if (window.Orejime && document.getElementById("Root")) {
  var orejimeConfig = {
    // Optional. You can customize the ID of the <div> that Orejime will create when starting up.
    // The generated <div> will be inserted at the beginning of the <body>.
    // If there is already a DOM element with this id, Orejime will use it instead of creating a new element.
    // defaults to "orejime".
    elementID: "ozact-cookies",
    appElement: "#Root",

    // Optional. You can customize the name of the cookie that Orejime uses for storing
    // user consent decisions.
    // defaults to "orejime".
    cookieName: "orejime",

    // Optional. You can set a custom expiration time for the Orejime cookie, in days.
    // defaults to 365.
    cookieExpiresAfterDays: 365,

    // Optional. You can provide a custom function to serialize the cookie contents.
    stringifyCookie: (contents) => JSON.stringify(contents),

    // Optional. You can provide a custom function to unserialize the cookie contents.
    parseCookie: (cookie) => JSON.parse(cookie),

    // You must provide a link to your privacy policy page
    privacyPolicy: "/cgu",

    // Optional. Applications configured below will be ON by default if default=true.
    // defaults to true
    default: true,

    // Optional. If "mustConsent" is set to true, Orejime will directly display the consent
    // manager modal and not allow the user to close it before having actively
    // consented or declined the use of third-party apps.
    // defaults to false
    mustConsent: true,

    // Optional. If "mustNotice" is set to true, Orejime will display the consent
    // notice and not allow the user to close it before having actively
    // consented or declined the use of third-party apps.
    // Has no effect if mustConsent is set to true.
    // defaults to false
    mustNotice: true,

    // Optional. You can define the UI language directly here. If undefined, Orejime will
    // use the value given in the global "lang" variable, or fallback to the value
    // in the <html> lang attribute, or fallback to "en".
    lang: "fr",

    // Optional. You can pass an image url to show in the notice.
    // If the image is not exclusively decorative, you can pass an object
    // with the image src and alt attributes: `logo: {src: '...', alt: '...'}`
    // defaults to false
    logo: "/logo.png",

    // Optional. Set Orejime in debug mode to have a few stuff
    // logged in the console, like warning about missing translations.
    // defaults to false
    debug: false,

    // You can overwrite existing translations and add translations for your
    // app descriptions and purposes. See `src/translations.yml` for a full
    // list of translations that can be overwritten
    translations: {
      fr: {
        ...OzactTerritoire.cookies,
        purposes: {
          analytics: "Publication de stastiques et amélioration d'usages",
        },
      },
    },

    // The list of third-party apps that Orejime will manage for you.
    // The apps will appear in the modal in the same order as defined here.
    apps: [
      {
        // The name of the app, used internally by Orejime.
        // Each name should match a name of a <script> tag defined in the
        // "Changing your existing third-party scripts" documentation step.
        name: "matomo-tag",

        // The title of you app as listed in the consent modal.
        title: "Fréquentation du site (Matomo)",

        // A list of regex expressions, strings, or arrays, giving the names of
        // cookies set by this app. If the user withdraws consent for a
        // given app, Orejime will then automatically delete all matching
        // cookies.
        //
        // See a different example below with the inline-tracker app
        // to see how to define cookies set on different path or domains.
        cookies: [
          "_pk_ref",
          "_pk_cvar",
          "_pk_id",
          "_pk_ses",
          "mtm_consent_removed",
          "mtm_consent_removed",
          "matomo_ignore",
          "matomo_sessid",
          "_pk_hsr",
        ],
        // Optional. The purpose(s) of this app. Will be listed on the consent notice.
        // Do not forget to add translations for all purposes you list here.
        purposes: ["analytics"],

        // Optional. A callback function that will be called each time
        // the consent state for the app changes. Passes
        // the `app` config as the second parameter as well.
        callback: function (consent, app) {
          // This is an example callback function.
          console.log(
            "User consent for app " + app.name + ": consent=" + consent,
          );
        },

        // Optional. If "required" is set to true, Orejime will not allow this app to
        // be disabled by the user.
        // See "Special cases" below for more information.
        // default to false
        required: false,

        // Optional. If "optOut" is set to true, Orejime will load this app
        // even before the user gave explicit consent.
        // We recommend always leaving this "false".
        // See "Special cases" below for more information.
        // defaults to false
        optOut: false,

        // Optional. If "default" is set to true, the app will be enabled by default
        // Overwrites the global "default" setting.
        // defaults to the value of the gobal "default" setting
        default: true,

        // Optional. If "onlyOnce" is set to true, the app will only be executed
        // once regardless how often the user toggles it on and off.
        // defaults to false
        onlyOnce: true,
      },
    ],
    // Optional. A list of categories under which apps will be classified.
    // This allows for a visual grouping of the different apps, along with a
    // description of their purpose.
    categories: [
      {
        name: "analytics",
        title: "Mesures d'audience",
        // The list of apps belonging to the category, referenced by name.
        apps: ["matomo-tag"],
      },
    ],
  };
  if (window.OzactTerritoire?.features?.matomo && !!Orejime)
    Orejime.init(orejimeConfig);
}
})