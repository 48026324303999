class OzactEventStore {

    constructor() {
        this.listeners = []
    }
    on(listener) {
        if(!listener) throw new Error("Error: no listener passed")
        this.off(listener)
        this.listeners.push(listener)
    }
    off(listener){
        if(!listener) return;
        this.listeners = this.listeners.filter(l=>l !== listener)
    }

    async trigger(event, dataset) {
        if(!event) return;
        await Promise.all(this.listeners.map(async (l) => l(event, dataset)));
        console.log(`event ${event} dispatched with ${dataset}`)
    }

}

export default new OzactEventStore();